// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import "./polyfills"

// Google maps breaks core js on IE 11 https://github.com/zloirock/core-js/issues/618
import "core-js/es/symbol/iterator"
import "@stimulus/polyfills"
import { Application } from "@hotwired/stimulus"
import { registerControllers } from 'stimulus-vite-helpers'

const application = Application.start()
const controllers = import.meta.glob('./*_controller.js', { eager: true })
registerControllers(application, controllers)
